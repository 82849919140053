<template>
  <!-- layout-container lesson 3 -->
  <div class="lesson67-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageA />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 白板部分 -->
      <WhiteBoard :canvasHeight="cvHeight" :canvasWidth="cvWidth" />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageA from "./3_SyllabusPage_A.vue";

import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import IntroductionPage from "./4_Introduction.vue";

import StoryPageOne from "./5_StoryPageOne.vue";
import RecognitionPageOne from "./6_RecognitionPageOne.vue";
import PracticePageOne from "./7_PracticePageOne.vue";
import RecognitionPageTwo from "./8_RecognitionPageTwo.vue";
import PracticePageTwo from "./9_PracticePageTwo.vue";
import PracticePageThree from "./10_PracticePageThree.vue";

import StoryPageTwo from "./11_StoryPageTwo.vue";
import RecognitionPageThree from "./12_RecognitionPageThree.vue";
import PracticePageFour from "./13_PracticePageFour.vue";

import StoryPageThree from "./14_StoryPageThree.vue";
import RecognitionPageFour from "./15_RecognitionPageFour.vue";
import RecognitionPageFive from "./16_RecognitionPageFive.vue";
import PracticePageFive from "./17_PracticePageFive.vue";
import SpeakingPage from "./18_SpeakingPage.vue";

import SingPage from "./19_SingPage.vue";
import WordsCardPage from "./20_WordsCardPage.vue";
import SummaryPage from "./21_SummaryPage.vue";
import RankMapPageTwo from "./22_RankMapPageTwo.vue";
import ByePage from "./23_ByePage.vue";

export default {
  name: "Lesson3",
  mixins: [lessonMixin],
  components: {
    SyllabusPageA,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    IntroductionPage,
    StoryPageOne,
    RecognitionPageOne,
    PracticePageOne,
    RecognitionPageTwo,
    PracticePageTwo,
    PracticePageThree,
    StoryPageTwo,
    RecognitionPageThree,
    PracticePageFour,
    StoryPageThree,
    RecognitionPageFour,
    RecognitionPageFive,
    PracticePageFive,
    SpeakingPage,
    SingPage,
    WordsCardPage,
    SummaryPage,
    RankMapPageTwo,
    ByePage,
  },
  data() {
    return {
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        IntroductionPage,
        StoryPageOne,
        RecognitionPageOne,
        PracticePageOne,
        RecognitionPageTwo,
        PracticePageTwo,
        PracticePageThree,
        StoryPageTwo,
        RecognitionPageThree,
        PracticePageFour,
        StoryPageThree,
        RecognitionPageFour,
        RecognitionPageFive,
        PracticePageFive,
        SpeakingPage,
        SingPage,
        WordsCardPage,
        SummaryPage,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson67-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
</style>
