<!-- <template>
  <div class="practice-container">
    <PracticePage :practiceList="practiceList" :buttonList="buttonList" />
  </div>
</template>
<script>
import PracticePage from "@/components/Course/CoursePage/PracticePage";
export default {
  data() {
    return {
      practiceList: [
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi1-right.svg"),
              isRight: true,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi1-wrong.svg"),
              isRight: false,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 2,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi2-wrong.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi2-right.svg"),
              isRight: true,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 0,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi3-wrong.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi3-right.svg"),
              isRight: true,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 0,
          // 0 无操作 1 正确 2 失败
        },
      ],
            buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi1.svg"),
          startNumberRange: 1,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi2.svg"),
          startNumberRange: 3,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi3.svg"),
          startNumberRange: 5,
          endNumberRange: 6,
        },
      ],
    };
  },
  components: {
    PracticePage,
  },
};
</script>
<style lang="scss" scoped>
.practice-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style> -->
<template>
  <div class="game-container">
    <NumberClickGame :ObjectList="ObjectList" :backImg="backImg"/>
  </div>
</template>

<script>
import NumberClickGame from "@/components/Course/GamePage/NumberClickGame";
export default {
  data() {
    return {
      backImg:{
        background:require("@/assets/img/16-GAMES/G28-click-reveal/background.svg")
      }
    };
  },
  computed: {
    ObjectList() {
      let objArr = [];
      for (let i = 1; i <= 5; i++) {
        let ele = {
          id: i,
          image1: require(`@/assets/img/16-GAMES/G28-click-reveal/${i}.svg`),
          image2: require(`@/assets/img/16-GAMES/G28-click-reveal/${i}-chinese.svg`),
        };
        objArr.push(ele);
      }
      return objArr;
    },
  },
  components: {
    NumberClickGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>