<!-- <template>
  <div class="game-container">
    <SpeakingSentence
      :buttonList="buttonList"
      :gameList="gameList"
      :isClick="false"
      :titleInfo="titleInfo"
      :lesson="1"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "Nǐ hǎo! Wǒ shì",
        hanzi: "你好！我是......",
      },
      gameList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G05-stage/background-dongdong2.svg"),
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G05-stage/background-xiaoai.svg"),
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/menu-dongdong.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G05-stage/menu-xiaoai.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
  .speaking-sentence-container {
    .title-area {
      width: 20% !important;
    }
  }
}
</style> -->
<template>
  <div class="page-container">
    <DrawingPage :bgImg="bgImg" />
  </div>
</template>

<script>
import DrawingPage from "@/components/Course/CoursePage/DrawingPage";
export default {
  data() {
    return {
      bgImg:require("@/assets/img/16-GAMES/G06-drawing/drawing-background.svg")
    };
  },
  components: {
    DrawingPage,
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  height: 100%;
}
</style>
