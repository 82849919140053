<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "yào",
          hanzi: "要",
          english: "to want;  would like",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-03-yao-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "bāozi",
          hanzi: "包子",
          english: "steamed bun(s)",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-03-baozi-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "bēi",
          hanzi: "杯",
          english: "cup; measure word for drinks",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-03-bei-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "Duìbuqǐ",
          hanzi: "对不起",
          english: "I'm sorry",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-03-duibuqi-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "Méi guānxi ",
          hanzi: "没关系",
          english: "Never mind",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-03-meiguanxi-bubble.svg`),
        },
        
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
