<template>
  <div class="sing-page-container " :class="{ 'layout-border': !isReviewPage }">
    <SingPage :singList="singList" :songInfo="songInfo" :timeStampList="timeStampList" :isReviewPage="isReviewPage"></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-67.mp3"),
      },
      timeStampList: [7.0, 10.9, 14.4, 18.1, 21.4, 25.5, 29.1, 32.2],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Bāozi,bāozi,sān ge bāozi.",
            chineseWords: "包子，包子，三个包子。",
          },
          {
            id: 2,
            spelling: "Wǒ yào chī sān ge bāozi.",
            chineseWords: "我要吃三个包子。",
          },
          {
            id: 3,
            spelling: "Niúnǎi,niúnǎi,yì bēi niúnǎi. ",
            chineseWords: "牛奶，牛奶，一杯牛奶。",
          },
          {
            id: 4,
            spelling: "Wǒ yào hē yì bēi niúnǎi.",
            chineseWords: "我要喝一杯牛奶。",
          },
          {
            id: 5,
            spelling: "Qǐng chī bāozi,qǐng hē niúnǎi.",
            chineseWords: "请吃包子，请喝牛奶。",
          },
          {
            id: 6,
            spelling: "Āiya!",
            chineseWords: "（哎呀）",
          },
          {
            id: 7,
            spelling: "Duìbuqǐ,duìbuqǐ.",
            chineseWords: "对不起，对不起。",
          },
          {
            id: 8,
            spelling: "Méi guānxi,méi guānxi.",
            chineseWords: "没关系，没关系。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
// .singpage-container {
//   width: calc(100% - 20px);
//   height: calc(100% - 40px);
// }
</style>
