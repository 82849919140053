<!-- <template>
  <div class="practice-container">
    <PracticePage :practiceList="practiceList" :buttonList="buttonList" />
  </div>
</template>
<script>
import PracticePage from "@/components/Course/CoursePage/PracticePage";
export default {
  data() {
    return {
      practiceList: [
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi1-right.svg"),
              isRight: true,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi1-wrong.svg"),
              isRight: false,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 2,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi2-wrong.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi2-right.svg"),
              isRight: true,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 0,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi3-wrong.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/card-laoshi3-right.svg"),
              isRight: true,
            },
          ],
          hanzi: "老师",
          pinyin: "lǎoshī",
          status: 0,
          // 0 无操作 1 正确 2 失败
        },
      ],
            buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi1.svg"),
          startNumberRange: 1,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi2.svg"),
          startNumberRange: 3,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-laoshi3.svg"),
          startNumberRange: 5,
          endNumberRange: 6,
        },
      ],
    };
  },
  components: {
    PracticePage,
  },
};
</script>
<style lang="scss" scoped>
.practice-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style> -->
<template>
  <div class="game-container">
    <SeesawGame
      :chooseHanziList="chooseHanziList"
      :gameInfo="gameInfo"
      :starNum="3"
      :stepsImgList="stepsImgList"
      :answerIdList="[141, 25]"
    />
  </div>
</template>

<script>
import SeesawGame from "@/components/Course/GamePage/SeesawGame";
export default {
  data() {
    return {
      gameInfo: {
        seasawBottomImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/base-ge.svg`),
        baseImg: require(`@/assets/img/16-GAMES/G34-scales/base-scales.svg`),
      },
      stepsImgList: [
        {
          id: 1, // yi
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-yi.svg`),
        },
        {
          id: 2,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san.svg`),
        },
        {
          id: 3,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-si.svg`),
        },
        {
          id: 41,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege.svg`),
        },
        {
          id: 42,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege.svg`),
        },
        {
          id: 5, // 
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-meimei.svg`),
        },
        {
          id: 6,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-laoshi.svg`),
        },
        {
          id: 7,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-baba.svg`),
        },
        {
          id: 12,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-yi-san.svg`),
        },
        {
          id: 141,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-yi-gege.svg`),
        },
        {
          id: 21,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san-yi.svg`),
        },
        {
          id: 241,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san-gege.svg`),
        },
        {
          id: 411,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege-yi.svg`),
        },
        {
          id: 412,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege-san.svg`),
        },


        {
          id: 242,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-san-gege.svg`),
        },
        {
          id: 25,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-san-meimei.svg`),
        },
        {
          id: 422,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-gege-san.svg`),
        },
        {
          id: 425,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-gege-meimei.svg`),
        },
        {
          id: 52,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-meimei-san.svg`),
        },
        {
          id: 542,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-meimei-gege.svg`),
        },
        {
          id: 37,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-si-baba.svg`),
        },
        {
          id: 73,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/scales-baba-si.svg`),
        },
      ],
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/lesson-12-story-1.svg`),
          wordsPinyin: "yì bēi shuǐ",
          wordsHanzi: "一杯水",
          hanziChooseList: [
            {
              pinyin: "yī",
              hanzi: "一",
              id: 1,
            },
            {
              pinyin: "shuǐ",
              hanzi: "水",
              id: 41,
            },

            {
              pinyin: "sān",
              hanzi: "三",
              id: 2,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-12/lesson-12-story-2.svg`),
          wordsPinyin: "sǎn bēi niúnǎi",
          wordsHanzi: "三杯牛奶",
          hanziChooseList: [
            {
              pinyin: "sān",
              hanzi: "三",
              id: 2,
            },
            {
              pinyin: "niúnǎi",
              hanzi: "牛奶",
              id: 5,
            },
            {
              pinyin: "gēge",
              hanzi: "哥哥",
              id: 42,
            },
          ]
        }
      ],
    };
  },
  components: {
    SeesawGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>