<!-- <template>
  <div class="game-container">
    <NumberClickGame :ObjectList="ObjectList" :backImg="backImg"/>
  </div>
</template>

<script>
import NumberClickGame from "@/components/Course/GamePage/NumberClickGame";
export default {
  data() {
    return {
      backImg:{
        background:require("@/assets/img/16-GAMES/G28-click-reveal/background.svg")
      }
    };
  },
  computed: {
    ObjectList() {
      let objArr = [];
      for (let i = 1; i <= 5; i++) {
        let ele = {
          id: i,
          image1: require(`@/assets/img/16-GAMES/G28-click-reveal/${i}.svg`),
          image2: require(`@/assets/img/16-GAMES/G28-click-reveal/${i}-chinese.svg`),
        };
        objArr.push(ele);
      }
      return objArr;
    },
  },
  components: {
    NumberClickGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style> -->
<template>
  <div class="game-container">
    <DragToDressGame
      :bgImgList="bgImgList"
      :bgImg="bgImg"
      :optionImgList="optionImgList"
      :hasStar="false"
    />
  </div>
</template>

<script>
import DragToDressGame from "@/components/Course/GamePage/DragToDressGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/background.svg"),
      bgImgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/girl-hat.svg"),
          id: 1,
          index: 1,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/girl-headband.svg"),
          id: 2,
          index: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/girl-wings.svg"),
          id: 3,
          index: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/girl-tail.svg"),
          id: 4,
          index: 4,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/girl-shoes.svg"),
          id: 5,
          index: 5,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/girl-dress.svg"),
          id: 6,
          index: 6,
        },
      ],
      optionImgList: [
        {
          id: 1,
          index: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/hat.svg"),
        },
        {
          id: 2,
          index: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/headband.svg"),
        },
        {
          id: 3,
          index: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/wings.svg"),
        },
        {
          id: 4,
          index: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/tail.svg"),
        },
        {
          id: 5,
          index: 5,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/shoes.svg"),
        },
        {
          id: 6,
          index: 6,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/dress.svg"),
        },
      ],
    };
  },
  components: {
    DragToDressGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>